const isTrial = (subscription) => {
    return subscription.plan_id === 1;
}

const isNotTrial = (subscription) => {
    return !(subscription.plan_id === 1);
}

const isTrialOver = (subscription) => {
    return subscription.plan_id === 1 && subscription.status === 0;
}

const isTrialNotOver = (subscription) => {
    return isTrial(subscription) && subscription.status !== 0;
}

const isSubscriptionOver = (subscription) => {
    return isNotTrial(subscription) && subscription.status === 0;
}

const isSubscriptionGoing = (subscription) => {
    return isNotTrial(subscription) && subscription.status !== 0 && !isSubscriptionCanceled(subscription) && !isSubscriptionActiveButCancelled(subscription);
}

const isSubscriptionCanceled = (subscription) => {
    return !!subscription.subscription.subscription_cancel_at && subscription.status === 0;
}

const isSubscriptionActiveButCancelled = (subscription) => {
    return !!subscription.subscription.subscription_cancel_at && subscription.status !== 0;
}

export {
    isTrial,
    isNotTrial,
    isTrialOver,
    isTrialNotOver,
    isSubscriptionOver,
    isSubscriptionCanceled,
    isSubscriptionGoing,
    isSubscriptionActiveButCancelled,
}
