import PropTypes from 'prop-types';
import {useMemo} from 'react';
import {CssBaseline} from '@mui/material';
import {ThemeProvider as MUIThemeProvider, createTheme, StyledEngineProvider} from '@mui/material/styles';

import typography from './typography';

ThemeProvider.propTypes = {
    children: PropTypes.node,
};
const {palette} = createTheme();
const {augmentColor} = palette;
const createColor = (mainColor) => augmentColor({color: {main: mainColor}});

export default function ThemeProvider({children}) {
    const themeOptions = useMemo(
        () => ({
            typography,
            root: {
                flexGrow: 1
            },
            palette: {
                primary: {
                    main: 'rgba(59, 125, 237, 1)',
                    // light: will be calculated from palette.primary.main,
                    // dark: will be calculated from palette.primary.main,
                    // contrastText: will be calculated to contrast with palette.primary.main
                },
                buttonOutlined: createColor('#202124'),
                grey: createColor('#bdbdbd'),
            }
        }),
        []
    );

    const theme = createTheme(themeOptions);

    return (
        <StyledEngineProvider injectFirst>
            <MUIThemeProvider theme={theme}>
                <CssBaseline/>
                {children}
            </MUIThemeProvider>
        </StyledEngineProvider>
    );
}
